<template>
  <div class="h-full cust_card p-2 mt-2" style="overflow: visible !important;">
    <div class="heading-3 text-text1">● Device List:</div>
    <div class="bottom_space rounded py-2 h-full pt-4 bg-white">
      <div class="flex items-center flex-wrap pb-2">
        
      </div>
      <div class="bottom_space flex justify-between">
          <div class="mb-2 flex">
            <div class="max-w-max mr-4">
              <div class="select_button text-sm text-text2">
              <SimpleDropdown :dropdownList="items" @callApiAsperSelectValue1="getLeaveDataAsPerPagination1"/>
              </div>
            </div>
            <div style="display:flex" class="search_customer_box text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-11 mr-4">
              <span class="mr-2 cursor-pointer"><img height="20px" width="20px" src="@/assets/images/svg/gray-search.svg" alt=""></span>
              <input class="focus:outline-none w-72 text-text2" autocomplete="off" type="text" name="" placeholder="Search" id="custSearchInput"
                v-model="searchForDevice"
                @input="getDeviceDebaounce"
              >
              <span v-if="searchForDevice.length > 0" class="cursor-pointer"><img @click="clearSearch()" height="12px" width="12px" src="@/assets/images/svg/clear-input.svg" alt="clearInput"></span>
            </div>
          </div>
        </div>
      <div>
      <div class="w-full">
        <div class="progress-bar" v-if="searchLoader">
          <div class="progress-bar-value"></div>
        </div>
      </div>
      <div style="overflow-y: auto; white-space: nowrap;" class="table_containder ">
        <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-5 px-3 py-3 items-center">
          <div class="org_name flex items-center p-1">
            <span class="cursor-pointer" :class="titleObj[6].isSel ? 'text-primary' : ''" @click="sortByColumn(6)">{{titleObj[6].title}} </span>
            <img v-if="titleObj[6].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img v-if="titleObj[6].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="org_name flex items-center p-1">
            <span class="cursor-pointer" :class="titleObj[0].isSel ? 'text-primary' : ''" @click="sortByColumn(0)">{{titleObj[0].title}} </span>
            <img v-if="titleObj[0].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img v-if="titleObj[0].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="small_col  flex items-center p-1">
            <span class="cursor-pointer" :class="titleObj[1].isSel ? 'text-primary' : ''" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
            <img class="pl-1" v-if="titleObj[1].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[1].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="small_col  flex items-center p-1">
            <span class="cursor-pointer" :class="titleObj[2].isSel ? 'text-primary' : ''" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
            <img class="pl-1" v-if="titleObj[2].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[2].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="small_col  flex items-center p-1">
            <span class="cursor-pointer" :class="titleObj[3].isSel ? 'text-primary' : ''" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
            <img class="pl-1" v-if="titleObj[3].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[3].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="date_col  flex items-center p-1 ">
            <span class="cursor-pointer" :class="titleObj[4].isSel ? 'text-primary' : ''" @click="sortByColumn(4)">{{titleObj[4].title}}</span>
            <img class="pl-1" v-if="titleObj[4].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[4].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="date_col  flex items-center p-1 ">
            <span class="cursor-pointer" :class="titleObj[5].isSel ? 'text-primary' : ''" @click="sortByColumn(5)">{{titleObj[5].title}}</span>
            <img class="pl-1" v-if="titleObj[5].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[5].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="w-24 text-center justify-center p-1">
          </div>
        </div>
        <div v-for="(data, index) in deviceList" :key="index" class="min-w-min group flex border border-gray-100  bg-white  text-sm px-3 py-2 items-center hover:bg-gray-50" :class="data.isOpen ? 'border-primary' : ''">
          <div class="org_name p-1">
            <span class="heading-4 text-primary cursor-pointer" v-if="data.orgName !== ''" @click="$router.push({name: 'OrganizationDetail', params: {orgId: data.organizationDetailId}})">{{data.orgName}} <span class="heading-7 ">({{ data.orgCode }})</span></span>
            <span class="" v-else>--</span>
          </div>
          <div class="org_name p-1">
            <span class="heading-4 text-text2 " v-if="data.firstName !== '' || data.lastName !== ''">{{data.firstName}} {{data.lastName}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="small_col p-1">
            <span class="heading-4 text-text2" v-if="data.buildVersion !== ''">{{data.buildVersion}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="small_col p-1">
            <span class="heading-4 text-text2" v-if="data.osVersion !== ''">{{data.osVersion}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="small_col p-1">
            <span class="heading-4 text-text2" v-if="data.os !== ''">{{data.os}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="date_col p-1">
            <span class="heading-4 text-text2" v-if="data.deviceModel !== ''">{{data.deviceModel}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="date_col p-1">
            <span class="heading-4 text-text2" v-if="data.lastActive !== ''">{{data.lastActive | dateTimeWithName}}</span>
            <span class="" v-else>--</span>
          </div>
          <div v-if="!data.isOpen" class="w-24 text-center justify-center">
            <div @click.stop="openDetail(data)" class="cursor-pointer heading-4"><i class="fa-solid fa-circle-question text-primary"></i></div>
          </div>
          <div v-if="data.isOpen" class="w-24 text-center justify-center">
            <div class="cursor-pointer heading-4"><i class="fa-solid fa-arrow-pointer text-primary"></i></div>
          </div>
        </div>
      </div>
      <div class="layout my-2" v-if="deviceList.length === 0">
        <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
          <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <span class="sr-only">Info</span>
          <div>
            <p class=" font-medium heading-4">No record found.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-2">
      <Pagination
        :totalDataCount="totalDeviceCount"
        :resetPagination="resetPaginationValues"
        @callApiAsPerPagination="getCustomerDataFromPagination"/>
    </div>
  </div>
  <div v-if="openDetailPopup" class="popup_overlay px-4">
    <div class="custom_dialog rounded-lg pb-4" style="width: 800px;max-height: 95%;" v-click-outside="hidePopup">
      <div class="bg-primary flex justify-between items-center p-2">
        <div class=" font-medium text-white heading-4">
          {{selectedobject.orgName}} <span class="heading-5">({{selectedobject.orgCode}})</span>
        </div>
      </div>
      <div class="p-2">
        <div class="heading-3 text-text2">● Device Detail:</div>
        <div class="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 p-2">
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">App Version</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.appVersion !== ''">{{selectedobject.deviceParseData.appVersion}}</span>
              <span class="text-text1 heading-5" v-else>--</span>
            </p>
          </div>
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">Build Version</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.buildVersion !== ''">{{selectedobject.deviceParseData.buildVersion}}</span>
              <span class="text-text1 heading-5" v-else>--</span>
            </p>
          </div>
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">OS</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.os !== ''">{{selectedobject.deviceParseData.os}}</span>
              <span class="text-text1 heading-5" v-else>--</span>
            </p>
          </div>
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">OS Version</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.osVersion !== ''">{{selectedobject.deviceParseData.osVersion}}</span>
              <span class="text-text1 heading-5" v-else>--</span>
            </p>
          </div>
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">Device Model</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.deviceModel !== ''">{{selectedobject.deviceParseData.deviceModel}}</span>
              <span class="text-text1 heading-5" v-else>--</span>
            </p>
          </div>
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">Device Unique ID</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.deviceUniqueId !== ''">{{selectedobject.deviceParseData.deviceUniqueId}}</span>
              <span class="text-text1 heading-5" v-else>--</span>
            </p>
          </div>
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">Device Brand</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.deviceBrand !== ''">{{selectedobject.deviceParseData.deviceBrand}}</span>
              <span class="text-text1 heading-5" v-else>--</span>
            </p>
          </div>
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">Device Resolution</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.deviceResolution !== ''">{{selectedobject.deviceParseData.deviceResolution}}</span>
              <span class="text-text1 heading-5" v-else>--</span>
            </p>
          </div>
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">Connectivity On</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.deviceConnectivityOn !== ''">{{selectedobject.deviceParseData.deviceConnectivityOn}}</span>
              <span class="text-text1 heading-5" v-else>--</span>
            </p>
          </div>
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">Device Local Time</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.deviceLocalTime !== ''">{{selectedobject.deviceParseData.deviceLocalTime | dateTimeWithName}}</span>
              <span class="text-text1 heading-5" v-else>--</span>
            </p>
          </div>
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">Battery Level</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.batteryLevel !== ''">{{Math.abs(selectedobject.deviceParseData.batteryLevel)}} %</span>
              <span class="text-text1 heading-5" v-else>--</span>
            </p>
          </div>
        </div>
        <div class="heading-3 text-text2 pb-1">● Permissions:</div>
        <div class="px-2 grid grid-cols-1 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2">
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">Camera</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.isCameraPermission"><i class="fas fa-check text-success"></i></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="!selectedobject.deviceParseData.isCameraPermission"><i class="fas fa-xmark text-error"></i></span>
            </p>
          </div>
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">Location</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.isLocationPermission"><i class="fas fa-check text-success"></i></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="!selectedobject.deviceParseData.isLocationPermission"><i class="fas fa-xmark text-error"></i></span>
            </p>
          </div>
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">File</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.isFilePermission"><i class="fas fa-check text-success"></i></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="!selectedobject.deviceParseData.isFilePermission"><i class="fas fa-xmark text-error"></i></span>
            </p>
          </div>
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">Contact</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.isContactsPermission"><i class="fas fa-check text-success"></i></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="!selectedobject.deviceParseData.isContactsPermission"><i class="fas fa-xmark text-error"></i></span>
            </p>
          </div>
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">Notification</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.isNotificationPermission"><i class="fas fa-check text-success"></i></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="!selectedobject.deviceParseData.isNotificationPermission"><i class="fas fa-xmark text-error"></i></span>
            </p>
          </div>
          <div class="p-1">
            <p class="mb-0 items-center flex">
              <span class="text-gray4 heading-5 font-semibold"><p class="mb-0" style="width:160px;">Mic</p></span>
              <span><p class="mb-0 dotwidth">:</p></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="selectedobject.deviceParseData.isMicPermission"><i class="fas fa-check text-success"></i></span>
              <span class="text-text1 font-semibold heading-5 overFlowParaA" v-if="!selectedobject.deviceParseData.isMicPermission"><i class="fas fa-xmark text-error"></i></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import ADMINAPI from '@/View/Admin/api/Admin.js'
import SimpleDropdown from '@/View/components/simpleDropdown.vue'
// import Pagination from '@/View/components/pagination.vue'
import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    Pagination,
    SimpleDropdown,
  },
  mixins: [deboucneMixin],
  props: ['orgId'],
  data () {
    return {
      openDetailPopup: false,
      mobileView: false,
      searchLoader: false,
      resetPaginationValues: false,
      searchForDevice: '',
      titleObj: [
        {title: 'Name', icon: '', sortName: 'firstName', sortVal: '', isSel: false},
        {title: 'Build version', icon: '', sortName: 'buildVersion', sortVal: '', isSel: false},
        {title: 'OS version', icon: '', sortName: 'osVersion', sortVal: '', isSel: false},
        {title: 'OS', icon: '', sortName: 'os', sortVal: '', isSel: false},
        {title: 'Device Model', icon: '', sortName: 'deviceModel', sortVal: '', isSel: false},
        {title: 'Last Active On', icon: 'fas fa-sort-amount-down', sortName: 'lastActive', sortVal: 'DESC', isSel: true},
        {title: 'Organization name', icon: '', sortName: 'orgName', sortVal: '', isSel: false},
    ],
      filterObject: {
        start: 0,
        length: 20,
        searchVal: '',
        sortBy: 'lastActive',
        sortOrder: 'DESC',
        organizationId: 0,
      },
      selectedobject: null,
      totalDeviceCount: 0,
      paginationLength: 1,
      filterCol: 'text-gray-400',
      page: 1,
      items: [20, 25, 30, 50, 100],
      limitResData: 20,
      paginationData: 20,
      deviceList: [],
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    this.resizeWindowHandler();
    document.title = 'Customer List'
    this.getDeviceDebaounce = this.debounce(function () {
      this.resetPaginationValues = true
      this.searchLoader = true
      console.log('debounde')
    }, 500)
    this.getEmployeeList()
  },
  watch: {
    // searchForDevice: {
    //   handler () {
    //     if (this.searchForDevice === null) {
    //       this.searchForDevice = ''
    //     }
    //     if (this.searchForDevice === '') {
    //       this.resetPaginationValues = true
    //       this.getEmployeeList()
    //     }
    //   }
    // },
    titleObj: {
      handler () {
        console.log('titleObj 123', this.titleObj)
      }
    },
    paginationData: {
      handler () {
        this.filterObject.length = this.paginationData
        this.showlistLoader = true
        this.getEmployeeList(this.showlistLoader)
      },
      deep: true
    }
  },
  methods: {
    openDetail (data) {
      this.selectedobject = data
      this.selectedobject.deviceParseData = JSON.parse(data.deviceInfo)
      this.openDetailPopup = true
      data.isOpen = true
      document.body.style = 'overflow: hidden;'
      console.log('this.selectedobject', this.selectedobject)
    },
    hidePopup () {
      document.body.style = 'overflow: visible;'
      this.selectedobject.isOpen = false
      this.openDetailPopup = false
    },
    addNewComp () {
      this.$router.push({name: 'AddNewCompetition'})
    },
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    clearSearch () {
      this.searchForDevice = ''
      this.searchLoader = false
      this.getEmployeeList()
    },
    getCustomerDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      let showlistLoader = false
      if (data.offset !== 0) {
        showlistLoader = true
      }
      this.getEmployeeList(showlistLoader)
    },
    sortByColumn (index) {
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          console.log('this.titleObj[i].icon', this.titleObj[i].icon)
          console.log('this.titleObj[i].sortVal', this.titleObj[i].sortVal)
          console.log('this.filterObject.sortOrder', this.filterObject.sortOrder)
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.titleObj[i].sortVal = 'DESC'
            this.filterObject.sortOrder = 'DESC'
            this.titleObj[i].isSel = true
          } else if (this.titleObj[i].icon === 'fas fa-sort-amount-down') {
            this.titleObj[i].icon = 'fas fa-sort-amount-up'
            this.titleObj[i].sortVal = 'ASC'
            this.filterObject.sortOrder = 'ASC'
            this.titleObj[i].isSel = true
          } else {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.filterObject.sortOrder = 'DESC'
            this.titleObj[i].sortVal = 'DESC'
            this.titleObj[i].isSel = true
          }
      } else {
          this.titleObj[i].icon = ''
          this.titleObj[i].sortVal = ''
          this.titleObj[i].isSel = false
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getEmployeeList(true)
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    getLeaveDataAsPerPagination1 (data) {
      this.filterObject.length = data
      this.getEmployeeList()
    },
    getEmployeeList () {
      console.log('this.routing', this.orgId)
      if (this.orgId === undefined) {
        this.filterObject.organizationId = 0
      } else {
        this.filterObject.organizationId = this.orgId
      }
      if (this.searchForDevice === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      ADMINAPI.GetDeviceList(
        this.filterObject.start,
        this.filterObject.length,
        this.searchForDevice,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        this.filterObject.organizationId,
        response => {
          console.log("response", response.Data)
          this.resetPaginationValues = false
          let temp = response.Data.tableRow === null ? [] : response.Data.tableRow
          temp.isOpen = false
          this.deviceList = temp
          console.log('this.deviceList', this.deviceList)
          this.totalDeviceCount = response.Data.count
          console.log('count', this.totalDeviceCount)
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}

.org_name {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.email_col {
  min-width: 250px !important;
  width: 250px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.date_col {
  min-width: 210px !important;
  width: 210px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  min-width: 175px !important;
  width: 175px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.search_customer_box {
  min-width: 335px !important;
  max-width: 335px !important;
}

.progress-bar {
  height: 2px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
::placeholder {
  font-size: 16px;
  color: #494949;
}

</style>
